import React from "react";

import appLogo from "../images/logo.png";

import appleStoreBadge from "../images/apple_store_badge.svg";
import googlePlayBadge from "../images/google_play_badge.png";

import coverImage from "../images/autonomous.png";

// endorsement images
import dreamhub_filled from "../images/dreamhub_filled.png";

// section images
import sunchallenger from "../images/sc23.jpg";
import delirover from "../images/delirover.jpg";
import robs from "../images/robs.jpg";
import tuktuk from "../images/tuktuk.jpg";

export const initialState = {
  // when in dev, change appURL to local url
  // appURL: 'http://localhost:3000',
  // when in production, change appURL to real url
  appURL: "https://epidrone.com",

  appLogo: appLogo,
  appName: "EpiDrone",

  coverTitle: "Empowering Tomorrow with Intelligent Robotics",
  coverText: "Driving Innovation in Autonomous Delivery, Maritime Automation, Robotic Cafés, and Sustainable Transportation",
  appleStoreBadge: appleStoreBadge,
  appleStoreLink: "",
  googlePlayBadge: googlePlayBadge,
  googlePlayLink: "",

  coverImage: coverImage,

  endorsementTitle: `Some of the technologies we use and support`,
  endorsementText: ``,
  endorsementList: [
    {
      title: `Python`,
      titleColor: `orangeRed`,
      image: dreamhub_filled,
      URL: ``,
    },
  ],

  sectionList: [
    {
      title: `Delirover`,
      text: `The Delirover is an advanced autonomous delivery robot equipped with specialized hot and cold storage compartments, ensuring the optimal temperature for various goods. Designed for high-speed navigation on bike lanes, Delirover combines efficiency and reliability, making it the perfect solution for urban deliveries. Initially prototyped and tested in 2019, Delirover has demonstrated exceptional performance in real-world scenarios, paving the way for the future of automated delivery services.`,
      image: delirover,
    },
    {
      title: `SunChallenger`,
      text: `A cutting-edge autonomous solar-powered boat equipped with electric propulsion and real-time connectivity. Offering remote control capabilities from anywhere, live video streaming, and advanced water quality data collection, it sets a new standard in maritime automation. Version 1, launched in 2018, successfully completed a 10-day journey. Version 2, introduced in 2019, features dual motors and enhanced steering for improved redundancy and data collection. Currently, a foldable Version 3 is in development to enable easier deployment in remote locations, broadening its operational scope.`,
      link: `https://sunchallenger.com/`,
      linkText: `Visit Website`,
      image: sunchallenger,
    },
    {
      title: `Rob's Café`,
      text: `Rob's Café is a futuristic robot-operated café that merges technology with the art of coffee making. At Rob's Café, robotic arms skillfully operate Italian espresso machines to craft the perfect brew, while AI-powered voice ordering makes the experience seamless and interactive. Robotic servants deliver your coffee and baked goods, ensuring efficiency and precision. In the fully automated back-kitchen, machines handle everything from dishwashing to restocking, offering a glimpse into the future of dining. Currently work in progress.`,
      image: robs,
    },
    {
      title: `Electric Tuk-Tuk Conversion`,
      text: `The Electric Tuk-Tuk Conversion project transformed a petrol-powered tuk-tuk into an eco-friendly electric vehicle with a lithium battery and a solar-powered roof for charging. By converting a second-hand vehicle and donating it to a local charity in Thailand, the initiative promoted sustainability and educated the community on environmental alternatives, contributing to a greener future and supporting local causes.`,
      link: `https://www.youtube.com/watch?v=FgXdBjvg3Z0`,
      linkText: `Watch`,
      image: tuktuk,
    },
  ],

  emailLink: "mailto:info@epidrone.com",
};

const initialContext = {
  state: initialState,
  dispatch: () => null,
};

export const Context = React.createContext(initialContext);
